<template>
  <div class="profile-config-form__field col-12">
    <div class="profile-config-form__field-title">{{ __('profile_config_form.foot_position') }}</div>

    <div class="row">
      <div class="input-radio-chip__option col-4">
        <input
            :id="`profile-config-form__foot-position--regular-${_uid}`"
            type="radio" value="regular" v-model="value"
        >
        <label :for="`profile-config-form__foot-position--regular-${_uid}`">
          {{ __('profile_config_form.foot_position.regular') }}
        </label>
      </div>

      <div class="input-radio-chip__option col-4">
        <input
            :id="`profile-config-form__foot-position--goofy-${_uid}`"
            type="radio" value="goofy" v-model="value"
        >
        <label :for="`profile-config-form__foot-position--goofy-${_uid}`">
          {{ __('profile_config_form.foot_position.goofy') }}
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import ProfileConfigFormField from '@/mixins/profile-config-form-field';
import Locales from '@/mixins/locales';

export default {
  name: 'foot_position',
  mixins: [
    Locales,
    ProfileConfigFormField('foot_position', 'regular'),
  ],
};
</script>
